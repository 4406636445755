<template>
  <div :style="getElementStyle">
    <el-row align="middle">
      <el-col :span="showLabel && !isDefalutPos ? 4 : data.description ? 20 : 24">
        <!-- <label for="text">{{data.label}}</label> -->

      </el-col>
      <el-col :span="showLabel && isDefalutPos ? 4 : 0">
        <el-popover
          v-if="data.description"
          title="Help"
          placement="too-start"
          trigger="click"
          :content="data.description"
        >
          <i
            class="el-icon-info"
            :title="data.description"
          ></i>
        </el-popover>
      </el-col>
    </el-row>
    <div
      class="icon-block"
      :style="getStyle"
    >
      <!-- <label for="text">{{data.label}}</label> -->
      <span
        v-if="isActive || isDataTableField"
        class="setting-icon"
        @click="openSettings"
      >
        <i class="el-icon-s-tools" />
      </span>
      <div class="icon avatar-uploader">
        <el-radio
        :label="data.label"
          :style="getCheckBoxStyle" 
          style="display: contents;"
        >{{ data.label }}</el-radio>
          <span
            v-if="data.validations.required"
            style="color: red; font-weight: bold; float: left; margin-left: 5px;"
          >
            *
          </span>
          <i
        class="el-icon-copy-document " v-if="!isDataTableField" @click="copyDocument" ></i>
        <span>
          <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
          >
            <i class="el-icon-info" slot="reference"></i>
          </el-popover>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RadioView",
  components: {},
  props: ["data", "value", "isActive", "fieldsData"],
  computed: {
    selectedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    getStyle() {
      return (
        `height:${this.data.height - 10}px;width:${this.data.width}px` +
        (this.data.styles && this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "")
      );
    },
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : "";
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
      }

      return borderStyle;
    },
    //gayatri starts
    isDefalutPos() {
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }

      // return isDefalutPosq;
    },
    //ends
    getCheckBoxStyle() {
      return `font-size:${this.data.height - 10}px;}`;
    }
  },
  data() {
    return {
      validations: [],
      isDefalutPosq: true,
      showLabel: false
    };
  },

  methods: {
    copyDocument() {
    this.$emit("copy-document", this.data.key);
  },
    openSettings() {
      this.$emit("settings");
    }
  }
};
</script>

<style lang="scss" scoped>
.avatar-uploader {
  height: inherit !important;
  justify-content: center !important;
}
.attachment_img {
  height: inherit !important;
}
</style>